<template>
    <div class="wrapper" @click="is_weixn" v-show="!(isXmsmk)">
        <div class="img-box" v-show="isShow"  @touchmove.prevent>
            <img style="width: 100%" src="/img/mask-ios.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            isShow: false
        };
    },
    watch: {},
    computed: {
        isXmsmk() {
            var ua = navigator.userAgent.toLowerCase();
            //不在微信端、市民卡端、i厦门app才显示
            if (ua.match(/xmsmk/i) == "xmsmk"||ua.match(/MicroMessenger/i) == "micromessenger"||ua.match(/innoapp/i) == "innoapp") {
            // if (ua.match(/xmsmk/i) == "xmsmk") {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                console.log('在微信端');
                // this.isShow = true
            } else {
                //跳转优惠券页面
                console.log('唤起app');
                // window.location.href = 'xmsmk://application/itt-free'
                // window.location.href = 'xmsmk://application/itt'
                var url_ios = 'xmsmk://application/itt';  //苹果打开app的链接(由苹果开发提供)
                var url_ios_download ='https://smk.ixiamen.org.cn/smk/download-page/#/download-5.0?type=download';//苹果下载的链接(由苹果开发提供)
                var url_android ="xmsmk://application/itt";//安卓打开app的链接(由安卓开发提供)
                var url_android_download = 'https://smk.ixiamen.org.cn/smk/download-page/#/download-5.0?type=download';//安卓下载app的链接(由安卓开发提供)
                var u = navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if(isiOS){
                    window.location.href = url_ios;
                    var loadTime = new Date();
                    // 如果打开超时说明是没有安装，则跳到下载的地址
                    setTimeout(function(){
                        var outTime = new Date()
                        if(outTime - loadTime > 800){
                            window.location.href = url_ios_download;
                        }
                    },1000)
                }else{
                    window.location.href = url_android;
                    var loadTime = new Date();
                    // 如果打开超时说明是没有安装，则跳到下载的地址
                    setTimeout(function(){
                        var outTime = new Date()
                        if(outTime - loadTime > 800){
                            window.location.href = url_android_download;
                        }
                    },1000)
                }
            }
        },
        
    },
    created() {
        
    },
    mounted() {},
};
</script>
<style scoped>
.wrapper {
    position: fixed;
    z-index: 20000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}
.img-box{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
}
</style>