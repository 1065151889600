import axios from 'axios'
import qs from 'qs'
function getParameter(data) {
    var parameter = '?'
    for (var item in data) {
        parameter = parameter + item + '=' + data[item] + '&'
    }
    parameter=parameter.substring(0,parameter.length-1)
    return parameter
}

//i厦门公众号 token授权链接
export function ixmToken(data) {
    return axios({
        url: `/api/login/ixm/token`,
        method: 'post',
        data: qs.stringify(data)
    })
}

//i厦门 i厦门公众号 登录
export function ixmAuth(data) {
    return axios({
        url: `/api/login/ixm/auth`,
        method: 'post',
        data: qs.stringify(data)
    })
}

//i团团【APP】-授权链接
export function ixmAppAuthorize(data) {
    return axios({
        url: `/api/login/ixm-app/oauth/authorize`,
        method: 'post',
        data: qs.stringify(data)
    })
}

//i厦门【APP】-登录
export function ixmAppLogin(data) {
    return axios({
        url: `/api/login/ixm-app/auth`,
        method: 'post',
        data: qs.stringify(data)
    })
}

//jsSDK授权
export function config(data) {
    return axios({
        url: `/api/wx/sdk/config`,
        method: 'post',
        data: qs.stringify(data)
    })
  }

//市民卡登录
export function login(data) {
    return axios({
        url: `/api/login/smk`,
        method: 'post',
        data: qs.stringify(data)
    })
}
//获取个人信息
export function userInfo(data) {
    return axios({
        url: `/api/user/info`,
        method: 'get',
    })
}
//查询轮播图
export function advertiseList(data) {
    return axios({
        url: `/api/banner/list${getParameter(data)}`,
        method: 'get',
    })
}

//首页分类类别
export function getSpecialList(data) {
    return axios({
        url: `/api/special/list`,
        method: 'get',
    })
}

//查询商品分类
export function category(data) {
    return axios({
        url: `/api/goods/category/list${getParameter(data)}`,
        method: 'get'
    })
}
//分页获取商品列表
export function goodsPage(data) {
    return axios({
        url: `/api/goods/page${getParameter(data)}`,
        method: 'get'
    })
}
//查询拼团商品列表
export function marketPage(data) {
    return axios({
        url: `/api/market/page${getParameter(data)}`,
        method: 'get'
    })
}
//按分组获取商品列表( 1->今日必买 ， 2->精品推荐)
export function goodsTag(data) {
    return axios({
        url: `/api/goods/tag${getParameter(data)}`,
        method: 'get'
    })
}
//市民超市-商品信息详情
export function goodsDetails(id) {
    return axios({
        url: `/api/goods/${id}`,
        method: 'get'
    })
}


//添加到购物车
export function addCart(data) {
    return axios({
        url: `/api/cart/item/add`,
        method: 'post',
        data: qs.stringify(data)
    })
}
//修改购物车sku数量
export function cartChange(data) {
    return axios({
        url: `/api/cart/item/change`,
        method: 'post',
        data: qs.stringify(data)
    })
}
//移出购物车
export function delCart(id) {
    return axios({
        url: `/api/cart/item/${id}`,
        method: 'DELETE'
    })
}

//购物车列表
export function cartList(data) {
    return axios({
        url: `/api/cart/item/list${getParameter(data)}`,
        method: 'get'
    })
}
//新增-编辑会员收货地址表
export function addressSave(data) {
    return axios({
        url: `/api/user/address/save`,
        method: 'post',
        data: data
    })
}
//默认收货地址
export function addressDefault(data) {
    return axios({
        url: `/api/user/address/default`,
        method: 'get',
    })
}
//会员收货地址列表
export function addressList(data) {
    return axios({
        url: `/api/user/address/list`,
        method: 'get',
    })
}
//删除会员收货地址
export function addressRemove(data) {
    return axios({
        url: `/api/user/address/remove${getParameter(data)}`,
        method: 'DELETE'
    })
}

//新增用户收藏表
export function collectSave(data) {
    return axios({
        url: `/api/user/collect/save`,
        method: 'post',
        data: qs.stringify(data)
    })
}

//删除用户收藏商品
export function collectRemove(data) {
    return axios({
        url: `/api/user/collect/remove${getParameter(data)}`,
        method: 'DELETE'
    })
}

//分页查询用户收藏表
export function cartPage(data) {
    return axios({
        url: `/api/user/collect/page${getParameter(data)}`,
        method: 'get'
    })
}

//订单创建
export function orderCreate(data) {
    return axios({
        url: `/api/order/create`,
        method: 'post',
        data: data
    })
}
//取消订单
export function cancelOrder(orderSn) {
    return axios({
        url: `/api/order/cancel/${orderSn}`,
        method: 'post',
    })
}

//退货原因表
export function reasonList(type) {
    return axios({
        url: `/api/return/apply/reason/${type}/list`,
        method: 'get'
    })
}

//退货申请
export function returnApply(orderItemId,data) {
    return axios({
        url: `/api/return/apply/${orderItemId}`,
        method: 'post',
        data: data
    })
}

//售后单详情
export function getReturnApply(data) {
    return axios({
        url: `/api/return/apply/detail`,
        method: 'get',
        params: data
    })
}

//售后单撤销申请
export function getReturnApplyCancel(orderItemId) {
    return axios({
        url: `/api/return/apply/cancel`,
        method: 'put',
        params: {
            orderItemId: orderItemId
        }
    })
}

//快递公司列表
export function getExpress() {
    return axios({
        url: `/api/express/list`,
        method: 'get',
    })
}


//快递单填写
export function putExpress(orderItemId, companyId, deliverySn) {
    return axios({
        url: `/api/return/apply/fill`,
        method: 'put',
        params: {
            deliverySn: deliverySn,
            orderItemId: orderItemId,
            companyId: companyId,
        }
    })
}

//售后单-分页查询
export function getReturnApplyPage(data) {
    return axios({
        url: `/api/return/apply/page`,
        method: 'get',
        params: data
    })
}

//确认收货
export function confirmOrder(orderSn) {
    return axios({
        url: `/api/order/confirm/${orderSn}`,
        method: 'post',
    })
}
//获取支付订单号
export function unifiedOrder(orderSn) {
    return axios({
        url: `/api/pay/unifiedOrder/${orderSn}`,
        method: 'post'
    })
}
//确认支付订单
export function orderPay(orderSn) {
    return axios({
        url: `/api/order/pay/query/${orderSn}`,
        method: 'get'
    })
}

//确认支付订单
export function orderPayH5(data) {
    return axios({
        url: `/api/order/pay/query`,
        method: 'get',
        params: data
    })
}
//市民超市-分页查询订单列表
export function orderPage(data) {
    return axios({
        url: `/api/order/page${getParameter(data)}`,
        method: 'get'
    })
}
//市民超市-订单状态数量
export function orderCount(data) {
    return axios({
        url: `/api/order/count${getParameter(data)}`,
        method: 'get'
    })
}
//市民超市-订单详情
export function orderDetails(id) {
    return axios({
        url: `/api/order/${id}`,
        method: 'get'
    })
}

//拼团商品详情
export function marketDetails(id) {
    return axios({
        url: `/api/market/${id}`,
        method: 'get'
    })
}

//物流信息
export function orderLogistics(orderSn,data) {
    return axios({
        url: `/api/order/logistics/${orderSn}`,
        method: 'post'
    })
}

//我的优惠劵
export function couponList(data) {
    return axios({
        url: `/api/coupon/list${getParameter(data)}`,
        method: 'get',
    })
}

//新人券包-列表
export function newCouponList() {
    return axios({
        url: `/api/coupon/new/gift/list`,
        method: 'get',
    })
}

//新人券包-领取
export function newCouponReceive() {
    return axios({
        url: `/api/coupon/new/gift/receive`,
        method: 'get',
    })
}

//可用优惠券
export function couponUsable(data) {
    return axios({
        url: `/api/coupon/usable`,
        method: 'post',
        data: data
    })
}

//领券中心领券
export function receive(code) {
    return axios({
        url: `/api/coupon/receive/${code}`,
        method: 'get',
    })
}

//文件上传
export function fileUpload(file) {
    let param = new FormData(); //创建form对象
    param.append('file',file,file.name);
    return axios({
        url: `/api/upload/image`,
        method: 'post',
        headers: { // 设置请求头
            "Content-Type": "multipart/form-data"
        },
        data: param  // 参数
    })
}

//领券中心 
export function getCatalogs(data) {
    return axios({
        url: `/api/catalog/getCatalogs`,
        method: 'post',
        data: data
    })
}

//领券
export function doReceive(data) {
    return axios({
        url: `/api/catalog/doReceive`,
        method: 'post',
        data: qs.stringify(data)
    })
}

//分享领券
export function doShare(data) {
    return axios({
        url: `/api/catalog/doShare`,
        method: 'post',
        data: qs.stringify(data)
    })
}