import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  }, {
    path: '/login',
    meta: { title: '登录' },
    component: () => import('@/views/login')
  }, {
    path: '/ixmLogin',
    meta: { title: '登录' },
    component: () => import('@/views/ixmLogin')
  }, {
    path: '/home',
    meta: { title: '首页', keepAlive: true },
    component: () => import('@/views/home')
  }, {
    path: '/bannerGoodsList',
    meta: { title: '轮播图商品列表', keepAlive: true },
    component: () => import('@/views/home/bannerGoodsList')
  }, {
    path: '/home/productList',
    meta: { title: '商品列表', keepAlive: true },
    component: () => import('@/views/home/productList')
  }, {
    path: '/home/rankingList',
    meta: { title: '吃喝玩乐榜', keepAlive: true },
    component: () => import('@/views/home/rankingList')
  }, {
    path: '/home/groupList',
    meta: { title: '超值拼团', keepAlive: true },
    component: () => import('@/views/home/groupList')
  }, {
    path: '/home/mustBuyList',
    meta: { title: '今日必抢', keepAlive: true },
    component: () => import('@/views/home/mustBuyList')
  }, {
    path: '/home/preferentialList',
    meta: { title: '9.9特惠', keepAlive: true },
    component: () => import('@/views/home/preferentialList')
  }, {
    path: '/home/localLifeDetails',
    meta: { title: '本地生活详情' },
    component: () => import('@/views/home/localLifeDetails')
  }, {
    path: '/home/groupDetails',
    meta: { title: '拼团详情' },
    component: () => import('@/views/home/groupDetails')
  }, {
    path: '/home/details',
    meta: { title: '详情' },
    component: () => import('@/views/home/details')
  }, {
    path: '/home/orderConfirm',
    meta: { title: '确认订单' },
    component: () => import('@/views/home/orderConfirm')
  }, {
    path: '/home/orderConfirm/coupons',
    meta: { title: '选择优惠券' },
    component: () => import('@/views/home/orderConfirm/coupons')
  }, {
    path: '/home/search',
    meta: { title: '搜索', keepAlive: true },
    component: () => import('@/views/home/search')
  }, {
    path: '/classification',
    meta: { title: '分类', keepAlive: true },
    component: () => import('@/views/classification')
  }, {
    path: '/cart',
    meta: { title: '购物车' },
    component: () => import('@/views/cart')
  }, {
    path: '/my',
    meta: { title: '我的' },
    component: () => import('@/views/my')
  }, {
    path: '/my/commonProblem',
    meta: { title: '常见问题' },
    component: () => import('@/views/my/commonProblem')
  }, {
    path: '/my/portrait',
    meta: { title: '选择头像' },
    component: () => import('@/views/my/portrait')
  }, {
    path: '/my/coupons',
    meta: { title: '优惠券' },
    component: () => import('@/views/my/coupons')
  }, {
    path: '/my/coupons/goodsList',
    meta: { title: '优惠券指定商品' },
    component: () => import('@/views/my/coupons/goodsList')
  }, {
    path: '/my/service',
    meta: { title: '客服' },
    component: () => import('@/views/my/service')
  }, {
    path: '/my/opinion',
    meta: { title: '意见反馈' },
    component: () => import('@/views/my/opinion')
  }, {
    path: '/my/collection',
    meta: { title: '我的收藏' },
    component: () => import('@/views/my/collection')
  }, {
    path: '/my/addressList',
    meta: { title: '收货地址' },
    component: () => import('@/views/my/addressList')
  }, {
    path: '/my/addressList/address',
    meta: { title: '收货地址' },
    component: () => import('@/views/my/addressList/address')
  }, {
    path: '/my/orderListBDSH',
    meta: { title: '订单列表' },
    component: () => import('@/views/my/orderListBDSH')
  }, {
    path: '/my/orderListBDSH/orderDetails',
    meta: { title: '订单详情' },
    component: () => import('@/views/my/orderListBDSH/orderDetails')
  }, {
    path: '/my/orderListSMCS',
    meta: { title: '订单列表' },
    component: () => import('@/views/my/orderListSMCS')
  }, {
    path: '/my/orderListSMCS/orderDetails',
    meta: { title: '订单详情' },
    component: () => import('@/views/my/orderListSMCS/orderDetails')
  }, {
    path: '/my/orderListSMCS/orderDetails/logistics',
    meta: { title: '订单物流' },
    component: () => import('@/views/my/orderListSMCS/orderDetails/logistics')
  }, {
    path: '/my/orderListSMCS/refundDetails/details',
    meta: { title: '退款详情' },
    component: () => import('@/views/my/orderListSMCS/refund/details')
  }, {
    path: '/my/orderListSMCS/refund',
    meta: { title: '申请退款' },
    component: () => import('@/views/my/orderListSMCS/refund')
  }, {
    path: '/my/orderListSMCS/refund/success',
    meta: { title: '退款申请成功' },
    component: () => import('@/views/my/orderListSMCS/refund/success')
  }
  , {
    path: '/my/orderListSMCS/refund/inputlogistic',
    meta: { title: '填写物流' },
    component: () => import('@/views/my/orderListSMCS/refund/inputlogistic')
  }, {
    path: '/my/afterService',
    meta: { title: '售后列表' },
    component: () => import('@/views/my/afterService')
  }, {
    path: '/my/invoice',
    meta: { title: '申请发票' },
    component: () => import('@/views/my/invoice')
  }, {
    path: '/payLoading',
    meta: { title: '支付查询中' },
    component: () => import('@/views/payLoading')
  }, {
    path: '/couponCenter',
    meta: { title: '领券中心' },
    component: () => import('@/views/couponCenter')
  }
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
