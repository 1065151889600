import wx from 'weixin-js-sdk'
import { config } from '../../api'
function getPageBaseUrl () {
    let baseURL = ''
    if (!window.location.origin) { // 兼容IE，IE11版本下location.origin为undefined
      window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    } else {
      baseURL = window.location.origin
    }
    return baseURL
}
function IXMshare (obj) {
    var data = {
        title: '厦门市民鹭岛生活·99节',
        desc: '惠民补贴、好物优选，抢先GO!9.9元专区~特惠来袭',
        link: location.origin + location.pathname + '#/home',
        imgUrl: location.origin + location.pathname + 'img/logo_03.png'
    }
    if (obj) {
        data = obj
    }
    wx.onMenuShareTimeline(data);
    wx.onMenuShareAppMessage(data);
}
function wxConfig(data) {
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ["onMenuShareTimeline","onMenuShareAppMessage"] // 必填，需要使用的JS接口列表
    });
    
   
    wx.ready(() => {
        

        IXMshare()     
    })
    wx.error(function (res) {
        console.log(res);
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
}
export default {
    install(Vue) {
        Vue.prototype.$wxConfig = function () {
            config({
                url: location.href.split('#')[0]
            }).then(res => {
                var data = res.data.data
                wxConfig(data)
            })
        }
        // Vue.prototype.$IXMshare = IXMshare
    }
}