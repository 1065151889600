var baseUrl = "";
if (process.env.NODE_ENV !== "development") {
  //开发环境下的代理地址，解决本地跨域跨域，配置在config目录下的index.js dev.proxyTable中
  // baseUrl = "https://j.mousenat.cn/"; //正式
    baseUrl = "https://itt.polypolygo.com/"; //正式
} else {
  // baseUrl = "https://j.mousenat.cn/"; //测试
  // baseUrl = 'https://itt.polypolygo.com/'
}

export default baseUrl;
