import router from "../router"
import store from '../store';

//路由验证
router.beforeEach((to, from, next) => {
  if (from.meta.keepAlive) {
    var str = from.path + 'ScrollTop'
    var scrollTop = document.body.scrollTop = document.documentElement.scrollTop
    sessionStorage.setItem(str, scrollTop)
  }
  // if (to.path=='/home'||to.path=='/classification') {
  //   // store.state.include = ['home','classification']
  // } else {
  //   if (to.meta.keepAlive) {
  //     store.state.include.push(to.name)
  //   }
  // }
  if (!to.meta.keepAlive) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  next();
});

router.afterEach((to, from, next) => {
  var str = to.path + 'ScrollTop'
  if (sessionStorage.getItem(str)) {
    var scrollTop = sessionStorage.getItem(str)
    console.log('scrollTop',scrollTop);
    setTimeout(()=>{
      document.body.scrollTop = document.documentElement.scrollTop = 0 
      // document.body.scrollTop = document.documentElement.scrollTop = scrollTop 
    },100)
  }
  
  
})