<template>
  <div id="app">
    <keep-alive :include="$store.state.include">
      <!-- -->
      <router-view />
    </keep-alive>
    <div id="goTop">
      <div class="btn-box" :style="{ right: serviceShow ? '.3rem' : '' }">
        <template v-if="!$route.query.preview">
          <div class="service" v-if="serviceIsShow" @click.stop="$router.push({ path: '/my/service' })"></div>
          <div class="share" v-if="isShow && shareShow" @click.stop="shareClick"></div>
        </template>
      </div>
      <div class="goTop flex" :style="{ right: goTopShow ? '.3rem' : '' }" @click.stop="goTop">
      </div>

    </div>
    <!-- 新人红包 -->
    <v-newRedPopup />
    <!-- 新人红包 -->

    <!-- 领劵中心 -->
    <!-- <v-receivingCenter /> -->
    <!-- 领劵中心 -->

    <v-shareCallback v-if="isFormal" />

    <van-overlay :show="$store.state.shareTs" z-index="10000" @click="$store.state.shareTs = false">
      <div class="shareTs-box">
        <img src="/img/shareTs.png" alt="">
      </div>
    </van-overlay>
  </div>
</template>
<script>
import shareCallback from '@/components/shareCallback'
import newRedPopup from '@/components/newRedPopup'
import receivingCenter from '@/components/receivingCenter'
export default {
  props: {},
  components: {
    'v-shareCallback': shareCallback,
    'v-newRedPopup': newRedPopup,
    'v-receivingCenter': receivingCenter,
  },
  data() {
    return {
      scrollTop: '',
      goTopShow: false,
      timer: null,
      shareShow: true,
      serviceShow: true,
      isShow: true,
      isFormal: false,
      serviceIsShow: true
    };
  },
  watch: {
    '$route.path'(val) {
      if (val != '/home/orderConfirm') {
        this.isShow = true
      } else {
        this.isShow = false
      }
      if (val != '/home/orderConfirm') {
        this.serviceIsShow = true
      } else {
        this.serviceIsShow = false
      }
    }
  },
  methods: {
    shareClick() {

      var agent = navigator.userAgent.toLowerCase()
      if (/micromessenger/.test(agent)) {
        this.$store.state.shareTs = true
      }
      this.$ittShare({
        title: '厦门市民鹭岛生活·99节',
        desc: '惠民补贴、好物优选，抢先GO!9.9元专区~特惠来袭',
        link: location.origin + location.pathname + '#/home',
        imgUrl: location.origin + location.pathname + 'img/logo_03.png'
      })
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // if(this.scrollTop>100){
      //   　
      // } else {
      //    clearTimeout(this.timer)
      //    this.goTopShow=false
      // }
      this.shareShow = true
      this.serviceShow = false
      this.goTopShow = false
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        var ua = navigator.userAgent
        // if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
        //   this.shareShow = true
        // }
        this.shareShow = true
        this.serviceShow = true
        if (this.scrollTop > 100) {
          this.goTopShow = true
        }
      }, 1000)

    },
    goTop() {
      let timer = null, _that = this;
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 100;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      })
    }
  },
  computed: {},
  created() {
    return false
    var ua = navigator.userAgent
    if (ua.toLowerCase().indexOf("xmsmk") == -1) {
      this.shareShow = true
    }
    if (process.env.NODE_ENV === 'production') {
      this.isFormal = true
    } else {
      this.isFormal = false
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.$wxConfig()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped>
#goTop {
  position: relative;
  z-index: 1000;
}

#goTop .btn-box {
  position: fixed;
  right: -1.1rem;
  transition: right 0.6s;
  bottom: 3rem;
}

#goTop .goTop {
  position: fixed;
  right: -1.1rem;
  bottom: 2rem;
  transition: right 0.6s;
  width: 1rem;
  height: 1rem;
  z-index: 1000;
  border-radius: 50%;
  background-image: url(/img/icon-goTop.png);
  background-size: 100% 100%;
}

.service {
  width: 1rem;
  height: 1rem;
  z-index: 1000;
  border-radius: 50%;
  background-image: url(/img/icon-service.png);
  background-size: 100% 100%;
}

.share {
  width: 1rem;
  height: 1rem;
  z-index: 1000;
  border-radius: 50%;
  background-image: url(/img/icon-share1.png);
  background-size: 100% 100%;
}

.loading-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

.shareTs-box img {
  position: absolute;
  top: .5rem;
  right: 1.2rem;
  width: 2.72rem;
  height: 2.13rem;
}
</style>
<style>
html {
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

@font-face {
  font-family: PingFang;
  src: url('/font/苹方黑体-中粗-简.ttf');
  font-display: swap;
}

#app {
  width: 100%;
  max-width: 7.5rem;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(238, 238, 238, 1);
}

* {
  margin: 0;
  padding: 0;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mb15 {
  margin-bottom: 0.15rem;
}

.flex {
  display: flex;
}

.fw-w {
  flex-wrap: wrap;
}

.fw-nw {
  flex-wrap: nowrap;
}

.fd-r {
  flex-direction: row;
}

.fd-c {
  flex-direction: column;
}

.fd-rr {
  flex-direction: row-reverse;
}

.ai-c {
  align-items: center;
}

.ai-fd {
  align-items: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.ai-bl {
  align-items: baseline;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

.jc-fd {
  justify-content: flex-end;
}

.jc-fs {
  justify-content: flex-start;
}

.line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

:-moz-placeholder {
  color: #bdbdbd !important;
  opacity: 1;
  font-weight: 400;
}

::-moz-placeholder {
  color: #bdbdbd !important;
  opacity: 1;
  font-weight: 400;
}

input:-ms-input-placeholder {
  color: #bdbdbd !important;
  opacity: 1;
  font-weight: 400;
}

input::-webkit-input-placeholder {
  color: #bdbdbd !important;
  opacity: 1;
  font-weight: 400;
}

input::-moz-input-placeholder {
  color: #bdbdbd !important;
  opacity: 1;
  font-weight: 400;
}

textarea::-webkit-input-placeholder {
  color: rgba(150, 150, 150, 1) !important;
}

*::-webkit-scrollbar {
  height: 0;
  display: none;
  /*隐藏滚动条*/
}
</style>
