<template>
  <van-popup v-model="show" 
  closeable
  close-icon="close">
      <!-- <div class="box flex fd-c ai-c">
          <div class="t1">新用户专享礼</div>
          <div class="t2">188元礼券包</div>
          <div class="list flex fd-c ai-c">
              <div class="item flex ai-c" v-for="(item, index) in list" :key="index">
                  <div class="item-l flex fd-c ai-c jc-c">
                      <div class="money" v-if="item.discountType==0">{{item.amount}}</div>
                      <div class="zk" v-if="item.discountType==1">{{item.proportion*10}}折</div>
                      <div class="lb">优惠券</div>
                  </div>
                  <div class="item-r">
                      <div class="name">{{item.name}}</div>
                      <div v-if="item.useType==0" class="mode">全场通用</div>
                      <div v-if="item.useType==1" class="mode">指定分类使用</div>
                      <div v-if="item.useType==2" class="mode">指定产品使用</div>
                  </div>
                  <div class="btn" @click="Click(item)">去使用</div>
              </div>
          </div>
          <div class="txt">{{list.length}}张优惠券已到账-到个人中心查看</div>
      </div> -->
      <div class="box1 flex fd-c ai-c">
          <div class="ClickBox" @click="Click()"></div>
          <div class="txt">{{list.length}}张优惠券已到账-到个人中心查看</div>
      </div>
  </van-popup>
</template>

<script>
import { newCouponList, newCouponReceive } from '../api'
export default {
  components:{},
  props:{},
  data(){
    return {
        list: [],
        show: false
    }
  },
  watch:{
      '$store.state.newGift' () {
          if (this.$store.state.newGift==0) {
              this.newCouponList()
          }
      }
  },
  computed:{
      
  },
  methods:{
      newCouponList() {
          newCouponList({}).then(res=>{
              this.list = res.data.data
              if (this.list&&this.list.length>0) {
                  this.show = !this.$store.state.newGift
              }
              this.newCouponReceive()
          })
      },
      newCouponReceive () {
          newCouponReceive({}).then(res=>{})
      },
      Click(item) {
        this.show = false
        this.$router.push({path: '/my/coupons'})
        // if (item.useType==0) {
        //     this.$router.push({path: '/home'})
        // } else {
        //     this.$router.push({path: '/my/coupons/goodsList',query: {id: item.id}})
            
        // }
      }
  },
  created(){
      
  },
  mounted(){}
}
</script>
<style scoped>
.van-popup{
    background-color: transparent;
}
.van-popup>>>.van-icon{
    top: 0;
    right: 0;
    font-size: .5rem;
}
.box{
    width: 5.93rem;
    height: 8.01rem;
    background-size: 100% 100%;
    background-image: url(/img/newRedBg.png);
}
.box .t1{
    font-size: .30rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #EE2C26;
    margin-top: .35rem;
    line-height: .32rem;
}
.box .t2{
    font-size: .48rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #EE2C26;
}
.list {
    width: 100%;
    max-height: 5rem;
    margin-top: 1.1rem;
    overflow-y: scroll;
}
.list .item{
    position: relative;
    width: 5.28rem;
    height: .90rem;
    min-height: .9rem;
    background: #FFF9ED;
    border-radius: .25rem;
    overflow: hidden;
    margin-bottom: .1rem;
}
.list .item .item-l {
    width: 1.55rem;
    height: 100%;
    background-color: #FFF0D0;
}
.list .item .item-l .money{
    position: relative;
    font-size: .48rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #F14439;
    line-height: .5rem;
}
.list .item .item-l .zk{
    position: relative;
    font-size: .42rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #F14439;
    line-height: .5rem;
}
.list .item .item-l .money::after{
    content: '￥';
    position: absolute;
    left: -.2rem;
    top: .1rem;
    font-size: .24rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #F14439;
}
.list .item .item-l .lb{
    font-size: .12rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #F14439;
}
.list .item .item-r .name{
    font-size: .24rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: .26rem;
    margin-left: .2rem;
}
.list .item .item-r .mode{
    font-size: .18rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: .2rem;
    margin-top: .06rem;
    margin-left: .2rem;
}
.list .item .btn{
    position: absolute;
    right: .18rem;
    bottom: .16rem;
    width: 1.10rem;
    height: .28rem;
    line-height: .28rem;
    text-align: center;
    font-size: .22rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFF9ED;
    border-radius: .12rem;
    background: #F14439;
}
.txt{
    margin-top: .1rem;
    font-size: .24rem;
    color: #fff;
}
.box1{
    width: 5.93rem;
    height: 8.01rem;
    background-size: 100% 100%;
    background-image: url(/img/kyqRed.png);
}
.ClickBox{
    width: 5.3rem;
    height: 4.9rem;
    margin-top: 2.4rem;
}
</style>