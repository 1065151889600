<template>
  <div class="empty flex fd-c ai-c jc-c">
      <template v-if="type=='cart'">
            <img src="/img/cart-empty.png" alt="">
            <div class="txt">购物车还是空的，快去挑选商品吧</div>
      </template>
      <template v-if="type=='coupon'">
            <img src="/img/coupon-empty.png" alt="">
            <div class="txt">暂无优惠券</div>
      </template>
      <template v-if="type=='order'">
            <img src="/img/order-empty.png" alt="">
            <div class="txt">你暂时没有相关订单哦</div>
      </template>
  </div>
</template>

<script>
export default {
  components:{},
  props:["type"],
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.empty{
    background-color: #fff;
    padding: .7rem 0;
}
.empty img{
    width: 3.77rem;
    height: 3.77rem;
    object-fit: contain;
}
.empty .txt{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #BABABA;
    margin-top: .8rem;
}
</style>