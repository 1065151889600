import wx from 'weixin-js-sdk'
export function ittShare(obj) {
    var ua = navigator.userAgent.toLowerCase();
    console.log(ua);
    console.log(ua.match(/MicroMessenger/i) == "micromessenger");
    if (ua.match(/xmsmk/i) == "xmsmk"||ua.match(/xmsmk/i) == "XMSMK") {
        var ua = navigator.userAgent
        var preloadJs = function () {
            // Promise语法是ES6⽀持的，其他不⽀持的请⾃⾏转成ES5
            return new Promise((resolve, rejct) => {
                if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
                    if (window.UmsApi === undefined) {
                        document.addEventListener('OnUmsApiReady',
                            function () {
                                resolve(true)
                            }, false)
                    } else {
                        resolve(true)
                    }
                } else {
                    // ⾮市⺠卡接⼊
                    reject('⾮市⺠卡接⼊')
                }
            })
        }

        function share() {
            UmsApi.globalization.share(obj,
                function (data) {
                    // 成功
                    // UmsApi.notification.toast("返回数据：" + JSON.stringify(data));
                }, function (data) {
                    // 失败
                    // UmsApi.notification.toast("返回数据：" + JSON.stringify(data));
                });
        }
        preloadJs().then(res => {
            // 调⽤市⺠卡相关⽅法
            share()
        })
    } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log('在微信端');
        wx.ready(() => {
            wx.onMenuShareTimeline(obj);
            wx.onMenuShareAppMessage(obj);
        })
    } else {
        obj.thirdSystemId = localStorage.getItem("thirdSystemId");
        obj.thirdResourceType = '1'
        console.log('在i厦门app');
        console.log(obj);
        ixm.shareHandle(obj)
    }
}