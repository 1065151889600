<template>
  <div class="receivingCenter"  v-if="isShow">
      <div class="btn" @click="receive()"></div>
      <van-popup v-model="show" class="popop" :close-on-popstate="true" :close-on-click-overlay="false">
          <div class="box" :style="'background-image: url(/img/popupBox'+state+'.png)'">
              <div class="popup-btn" @click="Click()"></div>
          </div>
          <van-icon @click="show=false" class="close" name="cross" />
      </van-popup>
  </div>
</template>

<script>
import { receive } from '../api'
export default {
  components:{},
  props:{},
  data(){
    return {
        show: false,
        state: 1,
        isShow: true
    }
  },
  watch: {
    '$route.path'(val) {
      if (val != '/home/orderConfirm'&&val != '/login'&&val != '/couponCenter') {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  },
  computed:{},
  methods:{
      receive() {
          this.$toast.loading({
              duration: 0,
              forbidClick: true
          })
          receive('48f292a1f0f44045b4f7b6e675fbd13c').then(res=>{
              this.state = 1
              this.show = true
          }).catch(res=>{
              if (res.data.code==1105) {
                    this.state = 2
                    this.show = true
              } else if (res.data.code==1106) {
                    this.state = 3
                    this.show = true
              } else {
                    this.$dialog.alert({
                        title: '提示',
                        message: res.data.message
                    })
              }
          }).finally(()=>{
              this.$toast.clear()
          })
      },
      Click() {
          this.show = false
          if (this.state==1) {
              this.$router.push({path: '/my/coupons'})
          }
          
      }
  },
  created(){},
  mounted(){}
}
</script>
<style scoped>
.receivingCenter{
    position: fixed;
    right: 0;
    top: 45vh;
    width: 1.12rem;
    height: 1.12rem;
    z-index: 2000;
}
.receivingCenter .btn{
    width: 1.12rem;
    height: 1.12rem;
    background-size: 100% 100%;
    background-image: url(/img/icon-receivingCenter.png);
}
.van-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff0;
}
.popop .box{
    position: relative;
    width: 4.74rem;
    height: 2.73rem;
    
    background-size: 100% 100%;
}
.box .popup-btn{
    position: absolute;
    bottom: .15rem;
    left: calc(50% - 1.5rem);
    width: 3rem;
    height: .5rem;
}
.close{
    font-size: .5rem;
    color: #fff;
    margin-top: .45rem;
}
</style>