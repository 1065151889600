import Vue from 'vue'
import axios from 'axios'
import { Dialog, Toast } from 'vant';
import baseUrl from '../config/index.js'
import router from '../router';
Vue.prototype.$axios = axios
axios.defaults.timeout = 60000


axios.defaults.baseURL = baseUrl


axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token');
    let tokenHeader = localStorage.getItem('tokenHeader');
    if (token) {
      config.headers[tokenHeader] = String(token);
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  })

axios.interceptors.response.use(
  response => {  //成功请求到数据
    // //这里根据后端提供的数据进行对应的处理
    if (response.data.code == 200) {
      return response
    } else if (response.data.code == 500) {
      Dialog.alert({
        title: '提示',
        message: response.data.message
      })
      return Promise.reject(response);
    } else if (response.data.code == 401) {
      router.app.$router.replace({path: '/login'})
      return Promise.reject(response);
    }else {
      return Promise.reject(response);
    }
  },
  error => {  //响应错误处理
    Toast.clear()
    // Dialog.alert({
    //   title: '提示',
    //   message: '请求超时'
    // })
    return Promise.reject(error)
  }
)




